<template>
  <div v-if="list.length > 0" ref="carousel">
    <h2 class="category" v-if="title">{{ title }}</h2>
    <b-carousel-list
      v-model="page"
      :data="list"
      :items-to-show="local_itemsToShow"
      :arrow="arrow"
      :as-indicator="asIndicator"
    >
      <template slot="item" slot-scope="item">
        <slot :item="item"></slot>
      </template>
    </b-carousel-list>
  </div>
</template>

<script>
import Vue from "vue";
import { Carousel, Image } from "buefy";

Vue.use(Carousel);
Vue.use(Image);

export default {
  name: "WorldspanCarousel",
  props: {
    // This defines the properties passed to the component.
    list: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    itemsToShow: {
      type: Number,
      default: 1,
    },
    iconSize: {
      type: String,
      default: "is-large",
    },
    arrow: {
      type: Boolean,
      default: true,
    },
    asIndicator: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  data: function () {
    return {
      page: 0,
      local_itemsToShow: 1,
    };
  },
  mounted() {
    this.local_itemsToShow = this.itemsToShow;
    this.local_itemsToShow = this.getVisibleColumns();
    this.$emit("ws:carousel:columns-resized", this.local_itemsToShow);
  },
  methods: {
    onResize: function () {
      this.local_itemsToShow = this.getVisibleColumns();
    },
    getVisibleColumns: function () {
      var w = window.innerWidth;
      if (this.itemsToShow > 1) {
        if (w < 1217) {
          return 2;
        }
      }
      return this.itemsToShow;
    },
  },
  watch: {
    local_itemsToShow: function (newValue) {
      this.$emit("ws:carousel:columns-resized", newValue);
    },
  },
};
</script>
<style lang="scss">
@import "~bulma";
@import "~buefy/src/scss/buefy/";
</style>